<template>
  <v-navigation-drawer
    v-model="sidebarMenu"
    src="../../assets/img/new3.png"
    app
    floating
    dark
    :permanent="sidebarMenu"
    :mini-variant.sync="mini"
    :mini-variant-width="100"
    style="padding: 0"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-avatar size="35" class="ma-1">
          <img src="../../assets/img/logoblue.png" alt="pp" class="dva" />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content class="text-truncate">
        Widyaiswara
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.href"
        @click="$emit('banner', item)"
      >
        <v-tooltip right max-width="200px" nudge-right="10px">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on">
              <v-badge
                :content="unread"
                :value="unread"
                color="green"
                overlap
                v-if="item.badge"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-badge>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list>
      <v-list-item
        v-for="(item, idx) in feedback"
        :key="idx"
        link
        :to="item.href"
        @click="$emit('banner', item)"
        :id="`v-step-${idx}`"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SidebarTeacher",
  props: ["toggleMini"],
  computed: {
    ...mapState(["unread"]),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
  data() {
    return {
      sidebarMenu: true,
      banner: "",
      items: [
        {
          title: "Beranda",
          tooltip:
            "Pada Beranda Anda dapat melihat ringkasan total akun peserta, coach, dan jumlah kelompok.",
          href: "/",
          icon: "mdi-view-dashboard",
        },
        {
          title: "Pelatihan",
          tooltip:
            "Menu ini digunakan untuk membuat dan mengelola pelatihan yang akan dilaksanakan.",
          href: "/list-event",
          icon: "mdi-laptop-windows",
        },
        {
          title: "Modul",
          tooltip:
            "Modul berisikan materi dan tugas yang akan digunakan dalam pelatihan.",
          href: "/list-module",
          icon: "mdi-book-open-page-variant",
        },
        {
          title: "Kelola Akun",
          tooltip: "Mengelola daftar akun peserta, kelompok dan coach.",
          href: "/management",
          icon: "mdi-account-child-circle",
        },
        {
          title: "Diskusi",
          badge: true,
          tooltip:
            "Melalui chat Anda dapat melakukan percakapan pribadi maupun kelompok yang berada di dalam sebuah pelatihan.",
          href: "/discussion",
          icon: "mdi-chat",
        },
        {
          title: "Leaderboard",
          tooltip: "Anda dapat melihat leaderboard",
          href: "/leaderboard",
          icon: "mdi-trophy",
        },
        {
          title: "Penilaian",
          tooltip:
            "Anda dapat memberikan penilaian terhadap  tugas yang telah di kerjakan oleh peserta pada menu ini.",
          href: "/feedback",
          icon: "mdi-lead-pencil",
        },
        {
          title: "broadcast",
          href: "/broadcast",
          icon: "mdi-broadcast",
        },
      ],
      feedback: [
        {
          title: "Manual",
          href: "/manual",
          icon: "mdi-file",
          fase: "Individu",
        },
        {
          title: "Umpan Balik",
          href: "/umpan-balik",
          icon: "mdi-comment-quote",
          fase: "Individu",
        },
      ],
    };
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style>
.bg {
  background: #002647;
}
/* scroll styling */

/* CHROME */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
