<template>
    <v-dialog v-model="dialogErr" persistent max-width="400px">
      <v-card>
       <div class="pa-3 d-flex flex-column align-center justify-ceneter">
       <h3>Sesi Anda telah berakhir</h3>
       <v-btn text color="indigo" @click="$emit('logout')">Login kembali</v-btn>
       </div>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: ["dialogErr"]
};
</script>

<style></style>
