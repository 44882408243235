<template>
  <v-navigation-drawer
    v-model="sidebarMenu"
    src="../../assets/img/new3.png"
    app
    floating
    dark
    :permanent="sidebarMenu"
    :mini-variant.sync="mini"
    :mini-variant-width="100"
    style="padding: 0"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-avatar size="35" class="ma-1">
          <img src="../../assets/img/logoblue.png" alt="pp" class="dva" />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content class="text-truncate">
        Gamification
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.href"
        @click="$emit('banner', item)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list>
      <v-list-item
        v-for="(item, idx) in feedback"
        :key="idx"
        link
        :to="item.href"
        @click="$emit('banner', item)"
        :id="`v-step-${idx}`"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SidebarMentor",
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    }
  },
  props: ["toggleMini"],
  data() {
    return {
      sidebarMenu: true,
      banner: "",
      items: [
        { 
          title: "Beranda", 
          href: "/", 
          icon: "mdi-view-dashboard" 
        },
        {
          title: "Anggota",
          href: "/member",
          icon: "mdi-account-group"
        },
        {
          title: "Leaderboard",
          href: "/leaderboard",
          icon: "mdi-trophy"
        },
        {
          title: "broadcast",
          href: "/broadcast",
          icon: "mdi-broadcast"
        },
        {
          title: "Penjadwalan",
          href: "/timetable",
          icon: "mdi-calendar"
        },
        {
          title: "Dokumen",
          href: "/file",
          icon: "mdi-file"
        },
        { 
          title: "Diskusi", 
          href: "/discussion", 
          icon: "mdi-chat" 
        },
        { 
          title: "Penilaian", 
          href: "/feedback", 
          icon: "mdi-lead-pencil" 
        },
      ],
      feedback: [
        {
          title: "Manual",
          href: "/manual",
          icon: "mdi-file",
          fase: "Individu",
        },
        {
          title: "Umpan Balik",
          href: "/umpan-balik",
          icon: "mdi-comment-quote",
          fase: "Individu",
        },
      ],
    };
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    logout() {
      delete localStorage.token;
      delete localStorage.id;
      delete localStorage.role_id;
      this.$router.go("login");
    }
  }
};
</script>

<style>
.bg {
  background: #002647;
}
</style>
