<template>
  <div class="home">
    <ErrMsg v-bind:dialogErr="dialogErr" v-on:logout="logOut"></ErrMsg>
    <v-app-bar flat :color="colorApp" style="height: 10vh">
      <div
        class="d-flex justify-space-between align-center"
        style="width: 100%"
      >
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            @click.stop="toggleMini = !toggleMini"
          ></v-app-bar-nav-icon>
          <h3 class="ml-5">{{ banner }}</h3>
        </div>
        <div
          class="d-flex align-center"
          v-if="role_id === '4' && precentage !== 100"
        >
          <section style="width: 200px">
            <v-progress-linear
              v-model="precentage"
              rounded
              color="light-green accent-4"
              height="27"
            >
              <small
                ><b>Kelengkapan profile : {{ precentage }}%</b></small
              >
            </v-progress-linear>
            <router-link to="/update-profile" style="text-decoration: none"
              ><v-btn small color="indigo" class="mt-1" dark
                >Lengkapi profil</v-btn
              ></router-link
            >
          </section>
        </div>
        <section v-if="alert">
          <h3 class="red--text _countDown">
            {{ alert }}
          </h3>
        </section>
        <div class="d-flex align-center">
          <div class="mr-3" v-if="role_id === '4'">
            <section class="d-flex" v-if="!loadData">
              <v-icon color="yellow accent-4"
                >mdi-currency-usd-circle-outline</v-icon
              >
              <h4 class="mr-2">{{ profile.student.poin }} Poin</h4>
              <v-divider vertical></v-divider>
            </section>
          </div>
          <div class="profile-set d-flex align-center ml-1">
            <div class="d-flex" v-if="loadData">
              <Proccess propsColor="indigo" /> Memuat ...
            </div>
            <div class="d-flex align-center" v-else>
              <v-avatar
                :color="profile.data_avatar ? 'none' : randomColors"
                size="40"
                class="mr-2"
              >
                <img
                  :src="ravatar"
                  alt="avatar"
                  class="_avatar"
                  v-if="profile.data_avatar"
                />
                <span class="white--text font-weight-bold" v-else>{{
                  avatar
                }}</span>
              </v-avatar>
              <h4>{{ username }}</h4>
            </div>
            <v-menu offset-y nudge-bottom="10px" content-class="drop">
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link to="/profile">
                  <v-list-item-title>
                    <v-icon>mdi-account-circle-outline</v-icon> Profil
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="logOut">
                  <v-list-item-title>
                    <v-icon>mdi-logout</v-icon> Keluar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </v-app-bar>
    <SidebarStudent
      v-on:banner="navTitle"
      v-bind:toggleMini="toggleMini"
      v-if="role_id == 4"
    />
    <SidebarMentor
      v-on:banner="navTitle"
      v-bind:toggleMini="toggleMini"
      v-if="role_id == 3"
    />
    <SidebarTeacher
      v-on:banner="navTitle"
      v-bind:toggleMini="toggleMini"
      v-if="role_id == 2"
    />
    <SidebarAdmin
      @banner="navTitle"
      v-bind:toggleMini="toggleMini"
      v-else-if="role_id == 1"
    />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import Proccess from "../components/_base/loader";
import SidebarStudent from "../components/Sidebar/SidebarStudent";
import SidebarAdmin from "../components/Sidebar/SidebarAdmin";
import SidebarTeacher from "../components/Sidebar/Sidebarteacher";
import SidebarMentor from "../components/Sidebar/SidebarMentor";
import ErrMsg from "./errMsg";
import { mapState } from "vuex";
import { GET_USER } from "../graphql/graphql";
import moment from "moment";
import "moment-timezone";
import Swal from "sweetalert2";
import { CREATE_BADGE_PESERTA } from "../graphql/Student";

export default {
  name: "Home",
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    mini() {
      return !(this.smAndDown || this.toggleMini);
    },
    breakpoint() {
      return JSON.stringify(this.$vuetify.breakpoint);
    },
    ...mapState([
      "role_id",
      "token",
      "precentage",
      "countDay",
      "visible",
      "badge_poin",
      "poin",
      "start_evt",
    ]),
    loadData() {
      return this.$apollo.queries.profile.loading;
    },
    fotoDummy() {
      const pp = "https://dashboard.anargya.id/img/fotoprofile.jpg";
      return pp;
    },
    ravatar() {
      let pp = null;
      if (this.profile) {
        if (this.profile.data_avatar) {
          pp = `${process.env.VUE_APP_GRAPHQL}/avatar/${this.profile.data_avatar.file}`;
        }
      }
      return pp;
    },
  },
  apollo: {
    profile: {
      query: GET_USER,
      result(data) {
        this.$store.commit("USER_DATA", data.data.profile);
        if (data.data.profile.student) {
          this.$store.commit(
            "PATH_GAME",
            JSON.parse(data.data.profile.grup.pelatihan.game)
          );

          let config = JSON.parse(data.data.profile.student.config);
          localStorage.setItem("no_soal", config.no_soal);
          this.$store.commit("POIN", data.data.profile.student.poin);
          this.$store.commit("MODULE", data.data.profile.grup.pelatihan.modul);

          let startIndividu = moment(
            data.data.profile.grup.pelatihan.start_date_individu
          );
          let startKelompok = moment(
            data.data.profile.grup.pelatihan.start_date_kelompok
          );
          let startD = null;
          let count = null;
          let today = moment().startOf("day");
          // JIKA PELATIHAN INDIVIDU
          if (data.data.profile.grup.pelatihan.modul.tipe == "INDIVIDU") {
            if (startIndividu) {
              let end_individu =
                data.data.profile.grup.pelatihan.end_date_individu;
              if (today.diff(end_individu, "days") > 0) {
                console.log("event individu sudah berakhir");
                this.$router.push("/end-event");
              }
              // CHECK COUNTDOWN
              let countDown = today.diff(end_individu, "days");
              if (countDown < 0) {
                countDown = countDown.toString().replace(/-/, " ");
                localStorage.setItem("countDown", parseInt(countDown));
                if (parseInt(countDown) < 3) {
                  this.alert = `Fase Individu berakhir ${parseInt(
                    countDown
                  )} hari lagi!`;
                  this.warn(countDown, "Individu");
                }
              } else if (this.today == end_individu) {
                this.alert = `Fase Individu berakhir hari ini!`;
              }

              this.$store.commit("END_EVENT", end_individu);
              this.$store.commit(
                "START_EVENT",
                data.data.profile.grup.pelatihan.start_date_individu
              );
              localStorage.setItem("fase", "Individu");
              startD = moment(startIndividu);
              count = today.diff(startD, "days") + 1;
            } else {
              count = "Belum ada jadwal";
            }
            // JIKA PELATIHAN hanya KELOMPOK
          } else if (
            data.data.profile.grup.pelatihan.modul.tipe == "KELOMPOK"
          ) {
            let end_kelompok =
              data.data.profile.grup.pelatihan.end_date_kelompok;
              
            if (today.diff(end_kelompok, "days") > 0) {
              console.log("event kelompok sudah berakhir");
              this.$router.push("/end-event");
            }
            // CHECK COUNTDOWN
            let countDown = today.diff(end_kelompok, "days");
            if (countDown < 0) {
              countDown = countDown.toString().replace(/-/, " ");
              console.log(countDown);
              localStorage.setItem("countDown", parseInt(countDown));
              if (parseInt(countDown) < 3) {
                this.alert = `Fase Kelompok berakhir ${parseInt(
                  countDown
                )} hari lagi!`;
                this.warn(countDown, "Kelompok");
              }
            } else if (this.today == end_kelompok) {
              this.alert = `Fase Kelompok berakhir hari ini!`;
            }

            this.$store.commit("END_EVENT", end_kelompok);
            this.$store.commit(
              "START_EVENT",
              data.data.profile.grup.pelatihan.start_date_kelompok
            );

            if (startKelompok) {
              localStorage.setItem("fase", "Kelompok");
              startD = moment(startKelompok);
              count = today.diff(startD, "days") + 1;
            } else {
              count = "Belum ada jadwal";
            }
            // JIKA PELATIHAN INDIVIDUKELOMPOK
          } else if (
            data.data.profile.grup.pelatihan.modul.tipe == "INDIVIDUKELOMPOK"
          ) {
            if (startIndividu) {
              let kelompok = data.data.profile.grup.pelatihan.end_date_kelompok;
              if (today.diff(kelompok, "days") > 0) {
                console.log(today.diff(kelompok, "days"))
                console.log(today.diff(kelompok, "days"));
                console.log("event inidkel sudah berakhir");
                this.$router.push("/end-event");
              }
              if (today.diff(startKelompok, "days") >= 0) {
                localStorage.setItem("fase", "Kelompok");
                count = today.diff(startKelompok, "days") + 1;

                let end_kelompok =
                  data.data.profile.grup.pelatihan.end_date_kelompok;

                // CHECK COUNTDOWN
                let countDown = today.diff(end_kelompok, "days");
                console.log("DBL", countDown);
                console.log(countDown < 0);
                if (countDown < 0) {
                  countDown = countDown.toString().replace(/-/, " ");
                  localStorage.setItem("countDown", parseInt(countDown));
                  if (parseInt(countDown) <= 3) {
                    this.alert = `Fase Kelompok berakhir ${parseInt(
                      countDown
                    )} hari lagi!`;
                    this.warn(countDown, "Kelompok");
                  }
                } else if (this.today == end_kelompok) {
                  this.alert = `Fase Kelompok berakhir hari ini!`;
                }

                this.$store.commit("END_EVENT", end_kelompok);
                this.$store.commit(
                  "START_EVENT",
                  data.data.profile.grup.pelatihan.start_date_kelompok
                );
              } else {
                localStorage.setItem("fase", "Individu");
                startD = startIndividu;
                count = today.diff(startD, "days") + 1;

                let end_individu =
                  data.data.profile.grup.pelatihan.end_date_individu;

                // CHECK COUNTDOWN
                let countDown = today.diff(end_individu, "days");
                if (countDown < 0) {
                  countDown = countDown.toString().replace(/-/, " ");
                  console.log("DBL", countDown);
                  localStorage.setItem("countDown", parseInt(countDown));
                  if (parseInt(countDown) < 3) {
                    this.alert = `Fase Individu berakhir ${parseInt(
                      countDown
                    )} hari lagi!`;
                    this.warn(countDown, "Individu");
                  }
                } else if (this.today == end_individu) {
                  this.alert = `Fase Individu berakhir hari ini!`;
                }
                this.$store.commit("END_EVENT", end_individu);
                this.$store.commit(
                  "START_EVENT",
                  data.data.profile.grup.pelatihan.start_date_individu
                );
              }
            } else {
              count = "Belum ada jadwal";
            }
          }

          this.$store.commit("COUNT_DAY", count);
          localStorage.setItem("day", count);
          const day = today.diff(startD, "days");
          if (day + 1 <= 0) {
            this.$router.push("/information");
          } else if (today >= this.start_evt) {
            this.$router.push("/");
          }
        }
      },
      error(error) {
        let err = error.graphQLErrors[0].message;
        this.errMsg = err;
        if (this.errMsg === "Unauthenticated.") {
          this.dialogErr = true;
          Swal.fire({
            icon: "error",
            title: "Sesi Anda telah berakhir",
            confirmButtonText: "Masuk lagi",
            confirmButtonColor: "#3F51B5",
            // position: "top",
            toast: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.logOut();
            }
          });
        }
        // this.logOut();
      },
    },
  },
  components: {
    SidebarStudent,
    SidebarAdmin,
    SidebarTeacher,
    SidebarMentor,
    Proccess,
    ErrMsg,
  },
  watch: {
    profile(val) {
      // create avatar
      if (val.nama_lengkap !== null) {
        this.username = val.nama_lengkap;
      } else {
        this.username = val.name;
      }
      let Str = this.username.split(" ");
      let { firstStr, lastStr } = "";
      firstStr = Str[0].toString().split("")[0];
      if (Str.length > 1) {
        lastStr = Str[Str.length - 1].split("")[0];
      } else {
        lastStr = Str[0].toString().split("")[1];
      }
      this.avatar = `${firstStr}${lastStr}`.toUpperCase();
      // push biodata
      if (this.role_id == 4) {
        let data = val.student;
        let allData = Object.keys(data).length;
        let count = 0;
        for (let q in data) {
          if (data[q] !== null) {
            count++;
          }
        }
        let percent = Math.ceil((count / allData) * 100);
        this.$store.commit("PRECENTAGE", percent);
        // checking badges
        let poin = val.student.poin;
        let existingBadge = val.data_badges.map((el) => {
          return el.badge;
        });
        this.$store.commit("EXISTING_BADGES", existingBadge);
        if (poin >= 2000) {
          this.mostPoint(existingBadge);
        }
      }
    },
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      toggleMini: false,
      banner: "",
      sidebar: 1,
      colorApp: "#f4f6f9",
      randomColors: "",
      username: null,
      avatar: null,
      errMsg: null,
      dialogErr: false,
      profile: null,
      unRead: 0,
      alert: "",
      countDwn: localStorage.getItem("countDown"),
      phase: localStorage.getItem("fase"),
    };
  },

  created() {},

  mounted() {
    this.randomColors = localStorage.getItem("xYzvQ");
    // let id = localStorage.getItem("id");
    // if (this.$route.path != "/discussion") {
    //   this.getChat();
    //   window.Echo.channel(`private-chat-${id}`).listen("ChatEvent", (e) => {
    //     if (e) {
    //       Swal.fire({
    //         icon: "info",
    //         title: "Anda mendapatkan pesan baru",
    //         showConfirmButton: false,
    //         timer: 5000,
    //         position: "top-end",
    //         toast: true,
    //       });
    //       this.getChat();
    //     }
    //   });
    // }
  },
  methods: {
    warn(countDown, phase) {
      let path = this.$route.path;
      if (path == "/" || path == "/update-profile") {
        Swal.fire({
          title: `fase ${phase} berakhir ${countDown} hari lagi`,
          icon: "warning",
          confirmButtonColor: "#3085d6",
        });
      }
    },

    navTitle(item) {
      this.banner = item.title;
      if (item.title) {
        this.toggleMini = true;
      }
    },
    getChat() {
      this.$store.dispatch("listChat").then((data) => {
        this.unRead = data.data
          .map((el) => {
            return parseInt(el.unread);
          })
          .reduce((a, b) => a + b, 0); //menjumlahkan pesan yang belum terbaca

        this.$store.commit("UNREAD", this.unRead);
      });
    },
    logOut() {
      this.ErrMsg = "";
      localStorage.clear();
      this.$router.go("login");
      this.dialogErr = false;
    },
    mostPoint(existingBadge) {
      for (let i = 0; i < existingBadge.length; i++) {
        if (6 === existingBadge.id) {
          break;
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_BADGE_PESERTA,
              variables: {
                user_id: localStorage.getItem("id"),
                badge_id: 6,
              },
            })
            .then((data) => {
              if (data.data.createBadgePeserta.id) {
                this.$store
                  .dispatch("returnBadge", { tipe: "poin" })
                  .then((data) => {
                    console.log(data.data.badge);
                    Swal.fire({
                      imageUrl: `${process.env.VUE_APP_GRAPHQL}/badges/${data.data.badge}`,
                      imageHeight: 150,
                      title: data.data.pesan,
                      showConfirmButton: true,
                      confirmButtonColor: "#3F51B5",
                      confirmButtonText: "Ok",
                    });
                  });
              }
            });
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
.list {
  background: none;
}
.profile-set {
  position: relative;
  cursor: pointer;
}
._avatar {
  object-fit: cover;
}
.drop {
  /* margin-top: 8px; */
  contain: initial;
  overflow: visible;
}
.drop::before {
  position: absolute;
  content: "";
  top: 0;
  right: 15px;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}

._countDown {
  animation: txt 2s ease-out infinite normal;
}

@keyframes txt {
  0% {
    transform: scale(0.9);
  }
  /* 25% {
    transform: scale(0.9)
  } */
  50% {
    transform: scale(1);
  }
  /* 75% {
    transform: scale(0.9);
  } */
  100% {
    transform: scale(0.9);
  }
}
</style>
