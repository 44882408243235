<template>
  <v-navigation-drawer
    v-model="sidebarMenu"
    src="../../assets/img/new3.png"
    dark
    app
    floating
    :permanent="sidebarMenu"
    :mini-variant.sync="mini"
    :mini-variant-width="100"
    style="padding: 0"
  >
    <v-list-item class="px-2" v-if="userProfile">
      <v-list-item-avatar link :to="updateProfile">
        <v-avatar size="35" class="ma-1">
          <img
            :src="`${env}/avatar/${userProfile.data_avatar.file}`"
            alt="pp"
            class="_avatar"
          />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content
        class="d-inline-block text-truncate text-truncate"
        style="max-width: 150px"
      >
        {{ userProfile.nama_lengkap }}
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list class="mb-10">
      <v-list-item
        v-for="item in individu"
        :key="item.title"
        link
        :to="item.href"
        @click="$emit('banner', item)"
      >
        <v-list-item-icon>
          <v-badge
            :content="unread"
            :value="unread"
            color="green"
            overlap
            v-if="item.badge"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="item in kelompok"
        :key="item.title"
        link
        :to="item.href"
        @click="$emit('banner', item)"
      >
        <v-list-item-icon>
          <v-badge
            :content="unread"
            :value="unread"
            color="green"
            overlap
            v-if="item.badge"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-list>
      <v-list-item
        v-for="(item, idx) in feedback"
        :key="idx"
        link
        :to="item.href"
        @click="$emit('banner', item)"
        :id="`v-step-${idx}`"
      >
        <v-list-item-icon>
          <v-badge
            :content="unread"
            :value="unread"
            color="green"
            overlap
            v-if="item.badge"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SidebarStudent",
  props: ["toggleMini"],
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
    ...mapState(["userProfile", "unread"]),
  },
  data() {
    return {
      sidebarMenu: true,
      banner: "",
      env: process.env.VUE_APP_GRAPHQL,
      updateProfile: "/update-profile",
      individu: [
        {
          title: "Beranda",
          href: "/",
          icon: "mdi-view-dashboard",
          fase: "Individu",
        },
        {
          title: "Penugasan",
          href: "/assignment",
          icon: "mdi-clipboard-text",
          fase: "Individu",
        },
      ],
      kelompok: [
        {
          title: "Penjadwalan",
          href: "/timetable",
          icon: "mdi-calendar",
          fase: "Individu",
        },
        {
          title: "Diskusi",
          badge: true,
          href: "/discussion",
          icon: "mdi-forum",
          fase: "Individu",
        },
        { title: "Dokumen", href: "/file", icon: "mdi-file", fase: "Individu" },
        {
          title: "Pengumpulan Tugas",
          href: "/submission",
          icon: "mdi-progress-upload",
          fase: "Individu",
        },
      ],
      feedback: [
        {
          title: "Manual",
          href: "/manual",
          icon: "mdi-file",
          fase: "Individu",
        },
        {
          title: "Umpan Balik",
          href: "/umpan-balik",
          icon: "mdi-comment-quote",
          fase: "Individu",
        },
      ],
    };
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style scoped>
.bg {
  background: #002647;
}
._avatar {
  object-fit: cover;
}
</style>
